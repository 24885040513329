import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HeaderComponent from './includes/Header/HeaderComponent';
import { HelmetProvider } from 'react-helmet-async';
import MyLoader from './UI/Loader/MyLoader';

// Ленивая загрузка компонентов
const MainPage = lazy(() => import('./templates/MainPage/MainPageComponent'));
const ProductPageComponent = lazy(() => import('./templates/ProductPage/ProductPageComponent'));
const CatalogPageComponent = lazy(() => import('./templates/CatalogPage/CatalogPageComponent'));
const CartPageComponent = lazy(() => import('./templates/CartPage/CartPageComponent'));
const AboutUsComponent = lazy(() => import('./templates/AboutUs/AboutUsComponent'));
const FooterComponent = lazy(() => import('./includes/Footer/FooterComponent'));
function App() {
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cartData'));
        setCartCount(cart ? cart.length : 0);
    }, []);

    return (
        <div className='wrapper'>
          <HelmetProvider>
                <HeaderComponent cartCount={cartCount}/> 
                <Suspense fallback={<MyLoader/>}>
                    <Routes>
                         <Route path="/" element={<MainPage />} />
                         <Route path="/product/:slug" element={<ProductPageComponent setCartCount={setCartCount}/>} />
                         <Route path="/catalog" element={<CatalogPageComponent />} />
                         <Route path="/cart" element={<CartPageComponent />} />
                         <Route path="/about-us" element={<AboutUsComponent />} />
                    </Routes>
                </Suspense>
                <FooterComponent />
            </HelmetProvider>
        </div>
    );
}

export default App;
